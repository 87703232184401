<template>
    <b-modal
        id="modalCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        hide-footer
        @hidden="hideModal"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <media-upload v-if="!editMode" @uploaded="onMediaUpload" />
            <media-details
                v-else
                :media="mediaToEdit"
                preview
                @cancel="hideModal"
                @updated="onMediaUpdate"
            />
        </div>
    </b-modal>
</template>

<script>
import MediaDetails from './Details';
import MediaUpload from './Upload';

export default {
    components: {
        MediaDetails,
        MediaUpload
    },

    props: {
        mediaToEdit: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    computed: {
        editMode() {
            return !!this.mediaToEdit && !!this.mediaToEdit.id;
        },

        uploadedBy() {
            const { user } = this.mediaToEdit;

            if (!user) {
                return 'System';
            }

            return `${user.first_name || ''} ${user.last_name || ''}`;
        },

        modalTitle() {
            return this.editMode
                ? `Edit ${this.mediaToEdit.name} | Uploaded by: ${this.uploadedBy}`
                : `Add new media`;
        }
    },

    methods: {
        onMediaUpload() {
            this.$toaster('Files have been uploaded.');

            this.$emit('fetchMedia');
            this.hideModal();
        },

        onMediaUpdate(media) {
            this.$emit('updated', media);
        },

        hideModal() {
            this.$bvModal.hide('modalCreateEdit');
            this.$emit('hide');
        }
    }
};
</script>
