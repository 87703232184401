<template>
    <layout>
        <page-header :title="title" :items="items" />

        <div class="card">
            <div class="card-body">
                <div class="mb-2">
                    <button
                        class="btn btn-success waves-effect waves-light"
                        @click="showAddModal"
                    >
                        <i class="fas fa-plus-circle" />
                        Add Media
                    </button>
                </div>
                <preview
                    :key="mediaKey"
                    is-deletable
                    @onSelectMedia="selectMedia"
                    @onDeleteMedia="removeMedia"
                />
            </div>
        </div>

        <create-edit-modal
            :media-to-edit="mediaToEdit"
            @hide="mediaToEdit = null"
            @fetchMedia="fetchMedia"
            @updated="onMediaUpdated"
        />
    </layout>
</template>

<script>
import { mapActions } from 'vuex';
import Preview from '@components/media/Preview';
import CreateEditModal from '@components/media/CreateEditModal';

export default {
    page: {
        title: 'Media'
    },

    components: {
        CreateEditModal,
        Preview
    },

    data() {
        return {
            title: 'Media',
            items: [
                {
                    text: 'Upstep',
                    to: '/'
                },
                {
                    text: 'Media',
                    active: true
                }
            ],
            mediaToEdit: null,
            mediaKey: 0,
            updateTime: 0
        };
    },

    methods: {
        ...mapActions({
            deleteMedia: 'media/delete'
        }),

        fetchMedia() {
            this.mediaKey++;
        },

        showAddModal() {
            this.$bvModal.show('modalCreateEdit');
        },

        selectMedia(media) {
            this.mediaToEdit = media[0];
            this.$bvModal.show('modalCreateEdit');
        },

        async removeMedia(id) {
            const confirmed = await this.$confirmationModal(
                'Do you want to delete this media?', {
                    title: 'Are you sure?'
                }
            );

            if (!confirmed) {
                return;
            }

            try {
                await this.deleteMedia(id);

                this.fetchMedia();

                this.$toaster('Media has been deleted!');
            } catch (err) {
                this.$toasterError(err.response.data.error);
            }
        },

        onMediaUpdated(media) {
            this.mediaToEdit = { ...media };
            this.mediaKey++;
        }
    }
};
</script>
